'use client'

import { styled } from 'styled-components'

import { ButtonAsA } from '@b-stock/bstock-react'
import {
  Breakpoints,
  Colors,
  Typography,
} from '@b-stock/bstock-react/design-system'

import AuctionCardSkeleton from '@components/common/auctionCard/AuctionCardSkeleton'
import { useAuctionQuery } from '@helpers/useAuctionQuery'

import AuctionCard from '../common/auctionCard/AuctionCard'
import Carousel, { CarouselItemWrapper } from '../common/carousel/Carousel'

const Content = styled.div`
  width: 100%;
  max-width: var(--maxPageWidth);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  ${CarouselItemWrapper} {
    padding: 0 0.125rem;
  }
`

const ContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const Title = styled.h3`
  ${Typography.Title3};
  color: ${Colors.Semantic.Neutral.light_black};
  padding: 0 0.125rem;

  @media ${Breakpoints.min.medium} {
    ${Typography.Title2};
  }
`

const ViewMore = styled(ButtonAsA)`
  ${Typography.Body3_Semibold};
  color: ${Colors.Semantic.Neutral.light_black};
  white-space: nowrap;
  padding: 0;

  &&:hover {
    background-color: transparent;
    color: ${Colors.Semantic.Info.highlight};
  }

  @media ${Breakpoints.max.small} {
    display: none;
  }
`

const ViewMoreMobile = styled(ViewMore)`
  display: none;

  @media ${Breakpoints.max.small} {
    display: block;
  }
`

export interface ShopMarketplaceCarouselProps {
  title: string
  carousel_name?: string
  viewMoreUrl: string
  endpoint: string | string[]
  minItemsToShowButtons?: number
}

const TitleSkeleton = styled.div`
  width: 12.5rem;
  height: 1.5rem;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
  border-radius: 0.25rem;

  @keyframes loading {
    0% {
      background-position: 200% 0;
    }
    100% {
      background-position: -200% 0;
    }
  }
`

const ViewMoreSkeleton = styled(TitleSkeleton)`
  width: 6.25rem;
  height: 1.25rem;
`

export default function ShopMarketplaceCarousel({
  title,
  viewMoreUrl,
  endpoint,
  carousel_name,
}: ShopMarketplaceCarouselProps) {
  const { data: listings, isLoading } = useAuctionQuery(endpoint)

  const skeletonCount = 10

  if (!isLoading && (!listings || listings.length === 0)) {
    return null
  }

  const auctionCards = isLoading
    ? Array(skeletonCount)
        .fill(null)
        .map((_, index) => (
          <AuctionCardSkeleton key={`skeleton-${index + 1}`} />
        ))
    : listings?.map((listing) => (
        <AuctionCard
          key={listing.auctionId}
          listing={listing}
          carousel_name={carousel_name}
        />
      )) || []

  return (
    <Content>
      <ContentHeader>
        {isLoading ? <TitleSkeleton /> : <Title>{title}</Title>}
        {isLoading ? (
          viewMoreUrl ? (
            <ViewMoreSkeleton />
          ) : null
        ) : viewMoreUrl ? (
          <ViewMore size="large" appearance="tertiary" href={viewMoreUrl}>
            View More
          </ViewMore>
        ) : null}
      </ContentHeader>
      <Carousel disabled={isLoading}>{auctionCards}</Carousel>
      {viewMoreUrl && !isLoading && (
        <ViewMoreMobile size="large" appearance="tertiary" href={viewMoreUrl}>
          View More
        </ViewMoreMobile>
      )}
    </Content>
  )
}
