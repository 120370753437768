export async function fetchData<T>(url: string): Promise<T> {
  return await fetch(url, {
    next: {
      revalidate: 4,
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }
      return response.json()
    })
    .then((data) => {
      return data as T
    })
    .catch((error: unknown) => {
      console.error('Error fetching data:', error)
      throw error
    })
}

export const LISTING_SEARCH_API_ENDPOINTS = {
  amazonBlue:
    '/all-listings/listings?offset=0&limit=50&storeFrontId=amz&city=Hebron',
  amazonUS: '/all-listings/listings?offset=0&limit=50&storeFrontId=amz',
  fewBidsEndingSoon:
    '/all-listings/listings?fewBids=true&endingSoon=true&offset=0&limit=50',
  target: '/all-listings/listings?offset=0&limit=50&storeFrontId=tgt',
  walmart: '/all-listings/listings?offset=0&limit=50&storeFrontId=wal',
}
