'use client'

import { useState, useRef, useEffect, useCallback } from 'react'

import { faAngleLeft, faAngleRight } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Image from 'next/image'
import { useIntl } from 'react-intl'
import { styled } from 'styled-components'

import { ButtonAsA } from '@b-stock/bstock-react'
import {
  Breakpoints,
  Colors,
  Typography,
} from '@b-stock/bstock-react/design-system'

import ComponentErrorBoundary from '@helpers/ComponentErrorBoundary'
import { useAnalytics } from '@helpers/telemetry/SegmentAnalytics'

import type { HeroBannerDataProps } from './heroBannerMockData'

const HeroBannerCarouselContainer = styled.section`
  position: relative;
  overflow: hidden;
  background: ${Colors.Semantic.BStock.default.lightBackground};
`

const HeroBannerCarouselTrack = styled.div<{
  $isTransitionEnabled: boolean
  $currentSlide: number
}>`
  display: flex;
  transition: ${({ $isTransitionEnabled }) =>
    $isTransitionEnabled ? 'transform 0.5s ease' : 'none'};
  transform: translateX(-${({ $currentSlide }) => $currentSlide * 100}%);
`

const HeroBannerCarouselSlide = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  flex: 0 0 100%;
  padding: 0 3rem;

  @media ${Breakpoints.min.medium} {
    padding: 0 9rem;
  }
`

const HeroBannerCarouselSlideImage = styled(Image)`
  position: absolute;
  width: 100%;
  height: 100%;
  inset: 0;
  object-fit: cover;
  z-index: 1;
`

const HeroBannerCarouselSlideContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  position: relative;
  text-align: center;
  z-index: 3;
  width: 100%;
  max-width: var(--maxPageWidth);
  margin: 0 auto;
  padding: 1.5rem 0;
  color: ${Colors.Semantic.Neutral.white};

  :nth-child(1) {
    max-width: 60rem;
  }

  :nth-child(2) {
    max-width: 55rem;
  }

  @media ${Breakpoints.min.medium} {
    padding: 3rem 0;
    text-align: left;
    align-items: flex-start;
  }
`

const HeroBannerCarouselSlideTitle = styled.h2`
  ${Typography.Title1}
`

const HeroBannerCarouselSlideSubtitle = styled.p`
  ${Typography.Body1_Semibold}
`

const HeroBannerCarouselNavBtn = styled.button<{
  $side: 'left' | 'right'
}>`
  position: absolute;
  ${({ $side }) => $side}: 0;
  top: 50%;
  z-index: 30;
  transform: translateY(-50%);
  margin: 0 0.5rem;
  color: ${Colors.Semantic.Neutral.white};
  opacity: 0.5;
  transition: 0.3s ease-out;

  &:hover {
    opacity: 1;
  }

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }

  @media ${Breakpoints.min.medium} {
    margin: 0 3rem;

    svg {
      width: 3rem;
      height: 3rem;
    }
  }
`

/**
 * Hero Banner Carousel component. Takes in an array of hero banner objects
 * containing a title, subtitle, banner image, and optional cta links. Banners
 * automatically rotate every 8 seconds by default with an option to increase
 * the interval time. Banners seamlessly rotate in an 'infinite' loop.
 */
const HeroBannerCarousel = ({
  banners,
  rotateInterval = 8000,
}: HeroBannerDataProps) => {
  const intl = useIntl()

  const [currentSlide, setCurrentSlide] = useState(1)
  const [isTransitioning, setIsTransitioning] = useState(false)
  const heroBannerTrackRef = useRef<HTMLDivElement>(null)

  const nextSlide = useCallback(() => {
    if (!isTransitioning) {
      setIsTransitioning(true)
      setCurrentSlide((prevSlide) => prevSlide + 1)
    }
  }, [isTransitioning])

  const prevSlide = useCallback(() => {
    if (!isTransitioning) {
      setIsTransitioning(true)
      setCurrentSlide((prevSlide) => prevSlide - 1)
    }
  }, [isTransitioning])

  const onTransitionEnd = () => {
    setIsTransitioning(false)

    // replace cloned first banner with natural first banner in array without a transition effect
    if (currentSlide === banners.length - 1) {
      setCurrentSlide(1)
    }

    // replace cloned last banner with natural last banner in array without a transition effect
    if (currentSlide === 0) {
      setCurrentSlide(banners.length - 2)
    }
  }

  const { trackButtonClicked } = useAnalytics()

  const handlePrimaryButtonClick = useCallback(
    (href: string, buttonText: string) => {
      // Segment analytics event and metadata transcribed from old wordpress code
      // TODO: Update entity_type to based on the role of the user once we
      // implement user authentication.
      trackButtonClicked(
        'homepage', // screen_name
        'banner_cta', // button_name
        'home_portal', // source
        'buyer', // entity_type
        {
          cta_name: buttonText.toLowerCase().replace(/\s+/g, '_'),
          url: document.URL,
          referrer: document.referrer,
          target_url: href,
        }
      )
    },
    [trackButtonClicked]
  )

  useEffect(() => {
    const autoScroll = setInterval(() => {
      nextSlide()
    }, rotateInterval)

    return () => clearInterval(autoScroll)
  }, [nextSlide, rotateInterval])

  return (
    <HeroBannerCarouselContainer>
      <HeroBannerCarouselNavBtn
        $side="left"
        aria-label={intl.formatMessage({
          id: 'Common.slide.previous',
        })}
        onClick={prevSlide}
        disabled={isTransitioning}
      >
        <FontAwesomeIcon icon={faAngleLeft} />
      </HeroBannerCarouselNavBtn>

      <HeroBannerCarouselTrack
        ref={heroBannerTrackRef}
        $currentSlide={currentSlide}
        $isTransitionEnabled={isTransitioning}
        onTransitionEnd={onTransitionEnd}
      >
        {banners.map((banner) => (
          <HeroBannerCarouselSlide key={crypto.randomUUID()}>
            <ComponentErrorBoundary>
              <HeroBannerCarouselSlideImage
                src={banner.image}
                aria-hidden
                alt=""
                loading="eager"
                width={1920}
                height={400}
              />

              <HeroBannerCarouselSlideContent>
                <HeroBannerCarouselSlideTitle>
                  {banner.title}
                </HeroBannerCarouselSlideTitle>

                <HeroBannerCarouselSlideSubtitle>
                  {banner.subtitle}
                </HeroBannerCarouselSlideSubtitle>

                <ButtonAsA
                  appearance="primary"
                  href={banner.primaryBtnLink}
                  target="_blank"
                  type="button"
                  onClick={() =>
                    handlePrimaryButtonClick(
                      banner.primaryBtnLink || '',
                      banner.primaryBtnText || ''
                    )
                  }
                >
                  {banner.primaryBtnText}
                </ButtonAsA>
              </HeroBannerCarouselSlideContent>
            </ComponentErrorBoundary>
          </HeroBannerCarouselSlide>
        ))}
      </HeroBannerCarouselTrack>

      <HeroBannerCarouselNavBtn
        $side="right"
        aria-label={intl.formatMessage({
          id: 'Common.slide.next',
        })}
        onClick={nextSlide}
        disabled={isTransitioning}
      >
        <FontAwesomeIcon icon={faAngleRight} />
      </HeroBannerCarouselNavBtn>
    </HeroBannerCarouselContainer>
  )
}

export default HeroBannerCarousel
